var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"hero is-fullheight",attrs:{"id":"login"}},[_c('div',{staticClass:"hero-body"},[_c('ValidationObserver',{ref:"observer",staticClass:"container",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"columns card-content-login"},[_c('div',{staticClass:"column panel-left"},[_c('img',{attrs:{"alt":"Login logo","src":require("@/assets/img/Arcr_logo.svg")}})]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"column",attrs:{"id":"form"}},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"header-title mb-5"},[_c('h1',[_vm._v(_vm._s(_vm.$t('auth.login')))])]),_c('ValidationProvider',{attrs:{"rules":"required","name":"Username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text",attrs:{"label":_vm.$t('auth.username'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('b-input',{ref:"username",attrs:{"type":"text","icon":"account-circle"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","vid":"password","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"label-text",attrs:{"label":_vm.$t('auth.password'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors,"id":"field"}},[_c('b-input',{key:_vm.typePassword,attrs:{"type":_vm.typePassword,"icon":"onepassword","icon-right-clickable":true,"icon-right":_vm.iconPassword},on:{"icon-right-click":_vm.changeView},nativeOn:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.submit)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('div',{staticClass:"buttons"},[_c('b-button',{attrs:{"native-type":"submit","expanded":""},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_vm._v(_vm._s(_vm.$t('auth.login_button')))])],1)],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }