import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import authModule from '@/store/auth'
import * as toast from '@/services/toast'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Login extends Vue {
  password = ''
  username = ''
  typePassword = 'password'
  iconPassword = 'eye'

  mounted() {
    ;(this.$refs.username as HTMLElement)?.focus()
    if (authModule.isAuthenticated) authModule.logout()
    else authModule.clearToken()
    const message: string | null = this.$route.query.message as string
    if (message && this.$route.query.nextUrl != '/')
      toast.error(this.$t(message) as string)
  }

  changeView(): void {
    this.typePassword = this.typePassword === 'password' ? 'text' : 'password'
    this.iconPassword = this.iconPassword === 'eye' ? 'eye-off' : 'eye'
  }

  async submit() {
    try {
      //se consigue el token
      await authModule.login({
        username: this.username,
        password: this.password,
      })
      //se extrae la info del token
      authModule.extractTokenData(JSON.stringify(authModule.AccessToken))
      //si el usuario se encuentra autorizado se procede a acceder al sistema
      if (authModule.AccessToken) {
        //si es la primera vez que se ingresa
        if (authModule.user.is_temp_login) {
          this.$router.push({ path: '/firstLogin' }).catch()
        }
        //Si ya se ha ingresado y solo se tiene un rol
        else if (
          authModule.user.groups.length == 1 &&
          !authModule.user.is_temp_login
        ) {
          this.$router.push({ path: '/dashboard' })
        } else {
          //por defecto
          this.$router.push({ path: '/dashboard' })
        }
      }
    } catch (error) {
      toast.error(this.$i18n.t('toast.login_error').toString())
      console.error(error)
    }
    /* try {
      await authModule.login({
        username: this.username,
        password: this.password,
      })
      const path = (
        this.$route.query.nextUrl ? this.$route.query.nextUrl : '/dashboard'
      ) as string
      this.$router.push(path)
    } catch (error) {
      toast.error(this.$i18n.t('toast.login_error').toString())
      console.error(error)
    } */
  }
}
